import account from "../de-DE/account.json";
import form from "../de-DE/form.json";
import changePassword from "../de-DE/changePassword.json";
import recoveryPassword from "../de-DE/recoveryPassword.json";
import checkout from "../de-DE/checkout.json";
import general from "../de-DE/general.json";
import cart from "../de-DE/cart.json";
import listing from "../de-DE/listing.json";
import product from "../de-DE/product.json";
import newsletter from "../de-DE/newsletter.json";
import validations from "../de-DE/validations.json";

export default {
  ...account,
  ...form,
  ...changePassword,
  ...recoveryPassword,
  ...checkout,
  ...general,
  ...cart,
  ...listing,
  ...product,
  ...newsletter,
  ...validations
};
